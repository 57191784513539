import type { Category } from '@prezly/sdk';
import { translations } from '@prezly/theme-kit-intl';
import { type PaginationProps } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { Button } from '@/components';
import type { StoryWithImage } from 'types';

import StoriesList from './StoriesList';
import { useInfiniteStoriesLoading } from './useInfiniteStoriesLoading';

import styles from './InfiniteStories.module.scss';

type Props = {
    initialStories: StoryWithImage[];
    featuredStories?: StoryWithImage[];
    pagination: PaginationProps;
    category?: Category;
    className?: string;
};

function InfiniteStories({
    initialStories,
    featuredStories = [],
    pagination,
    category,
    className,
}: Props) {
    const { formatMessage } = useIntl();
    const filterQuery =
        featuredStories.length > 0 ? { id: { $nin: featuredStories.map((s) => s.id) } } : undefined;

    const { canLoadMore, isLoading, loadMoreStories, stories } = useInfiniteStoriesLoading(
        initialStories,
        pagination,
        category,
        ['thumbnail_image'],
        filterQuery,
    );

    return (
        <div className={classNames(styles.container, className)}>
            <StoriesList stories={stories} withoutHighlightedStory />

            {canLoadMore && (
                <Button
                    variation="primary"
                    onClick={loadMoreStories}
                    isLoading={isLoading}
                    className={styles.loadMore}
                >
                    {formatMessage(
                        isLoading ? translations.misc.stateLoading : translations.actions.loadMore,
                    )}
                </Button>
            )}
        </div>
    );
}

export default InfiniteStories;
