import { type PaginationProps } from '@prezly/theme-kit-nextjs';

import StoriesList from '@/modules/InfiniteStories/StoriesList';
import type { StoryWithImage } from 'types';

import InfiniteStories from '../InfiniteStories';
import Layout from '../Layout';

import { Section } from './components';

import styles from './Stories.module.scss';

interface Props {
    featuredStories: StoryWithImage[];
    stories: StoryWithImage[];
    pagination: PaginationProps;
}

function Stories({ stories, featuredStories, pagination }: Props) {
    const hasFeaturedStories = featuredStories.length > 0;
    return (
        <Layout>
            {hasFeaturedStories ? (
                <>
                    <Section heading="Featured stories">
                        <StoriesList stories={featuredStories} withoutHighlightedStory />
                    </Section>
                    <Section heading="Recent stories">
                        <InfiniteStories
                            className={styles.container}
                            featuredStories={featuredStories}
                            initialStories={stories}
                            pagination={pagination}
                        />
                    </Section>
                </>
            ) : (
                <InfiniteStories
                    featuredStories={featuredStories}
                    initialStories={stories}
                    pagination={pagination}
                />
            )}
        </Layout>
    );
}

export default Stories;
