import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    heading: ReactNode;
}

export function Section({ children, heading }: Props) {
    return (
        <section>
            <h2>{heading}</h2>
            <div>{children}</div>
        </section>
    );
}
